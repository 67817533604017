// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// images
import MapImage from 'images/mapsImages/asasul-index.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';

import AddressTimes from 'components/units/unitPages/unit/addressTimes';
// import Downloads from 'components/unitPages/details/downloads'
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';
import BreadCrumb from 'components/breadCrumb';
import HealthInsurance from 'components/HealthInsurance';
import Components from 'components/CmsComponents/';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  padding: ${props => props.padding};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    padding: ${props => props.paddingLaptop};
    ${props =>
      props.borderTopLaptop &&
      css`
        border-top: 1px solid #c6c8cc;
      `}

    ${props =>
      props.borderBottomLaptop &&
      css`
        border-bottom: 1px solid #c6c8cc;
      `}
      
    ${props =>
      props.marginLaptop &&
      css`
        margin: ${props.marginLaptop};
      `}
  }
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    WithoutItemBg
    singleText={getMarkdown(page, 'ckhnu3xuo06pq0a71hadpt6wq')}
    servicesList={getMarkdown(page, 'ckhnu3xuo06pq0a71hadpt6wq', true)}
    asset={getMarkdown(page, 'ckhnu3xuo06pq0a71hadpt6wq', false, true)}
    containerNoBorderBottomDesk
    containerMarginBottomLap="0"
  />
);

const renderPage = (page, isDesktop, location) => {
  const components = page[0]?.components ?? [];
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <BreadCrumb
          details
          markdown={getMarkdown(page, 'ckhnst34g04fd0a73hlutn8wj', true)}
        />
        <DetailsTitle
          titleWidth="20rem"
          titleWidthMobile="18rem"
          markdown={getMarkdown(page, 'ckhnsy6rk04qb0a73ya6h267e', true)}
        />
      </Part>
      {!isDesktop && renderFeaturedMarketing(page)}
      <Container>
        <Part gridColumn={'1 / span 6'}>
          {isDesktop && renderFeaturedMarketing(page)}
          {/* About */}
          <TextSection
            isAbout
            markdown={getMarkdown(page, 'ckhnvb4mw00b70a73m5gw022v', true)}
            gridArea="ABT"
            doNotApplyDisplayBlock
            isAboutMobile
            isShowing
          />
          {/* Structure */}
          <TextSection
            markdown={getMarkdown(page, 'ckhnvo2oo00qf0a733gkzow8n', true)}
            gridArea="STRUC"
            doNotApplyDisplayBlock
            isAboutMobile
            isShowing
          />
          {/* Others Units Mobile*/}
          <TextSection
            markdown={getMarkdown(page, 'ckiqx3axc2t0l0971ntpdeo5c', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
          />
        </Part>
        <Part gridColumn={'8 / span 5'}>
          <AddressTimes
            isShowingAttention
            markdown={getMarkdown(page, 'ckhnuhyhc076d0b70ktcze8c5', true)}
            fragments={getMarkdown(page, 'ckhnuhyhc076d0b70ktcze8c5')}
            location={{ lat: -15.8363042, lng: -47.9126317 }}
            zoom={16}
            isDetails
            isMarginLeft
            mapPreview={MapImage}
            isStatic
            isDesktop={isDesktop}
          />
          {/* Schedule */}
          <TextSection
            markdown={getMarkdown(page, 'ckhnuwlhk07ng0a240f43fz6f', true)}
            gridArea="SCH"
            isShort
            isAboutMobile
            isShowing
          />
          {/* Parking */}
          <TextSection
            markdown={getMarkdown(page, 'ckhnv05ko07rx0a241s4hws73', true)}
            gridArea="PAR"
            isShort
            isAboutMobile
            isShowing
          />
          {/* <PublicTransportation
          markdown={getMarkdown(
            page,
            'canaisHSLDiagnosticoAsaSulPublicTransportTexts',
            true
          )}
          fragments={getMarkdown(
            page,
            'canaisHSLDiagnosticoAsaSulPublicTransportTexts'
          )}
        /> */}
          {/* <Downloads /> */}

          {/* Others Units */}
          <TextSection
            markdown={getMarkdown(page, 'ckiqx3axc2t0l0971ntpdeo5c', true)}
            isShort={true}
            gridArea="PAR"
            isAboutMobile
            isOthersUnits
            isOthersUnitsMobile
            isShowing
          />
          {isDesktop && (
            <SocialShare
              location={location}
              title="Unidade Diagnósticos Asa Sul"
            />
          )}
        </Part>
      </Container>
      {!isDesktop && (
        <SocialShare location={location} title="Unidade Diagnósticos Asa Sul" />
      )}
      <Part
        gridColumn={'1 / -1'}
        padding="0 0 4.375rem"
        paddingLaptop="1.25rem 0"
        marginLaptop="0 -1.25rem"
        id="convenio"
      >
        <HealthInsurance defaultUF="DF" />
      </Part>
      <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </Part>
    </GridContainer>
  );
};

const DiagnosticoAsaSul = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  // ====== WEBCHAT ====== //
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src =
  //     'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705/assets/js/bootstrap.min.js';
  //   document.body.appendChild(script);

  //   const handleScriptLoad = () => {
  //     try {
  //       window.embeddedservice_bootstrap.settings.language = 'pt_BR';
  //       window.embeddedservice_bootstrap.init(
  //         '00D41000000exwd',
  //         'Webchat_Agendamento_Sites',
  //         'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705',
  //         {
  //           scrt2URL: 'https://hospitalsiriolibanes.my.salesforce-scrt.com',
  //         }
  //       );
  //     } catch (err) {
  //       console.error('Error loading Embedded Messaging: ', err);
  //     }
  //   };

  //   script.addEventListener('load', handleScriptLoad);

  //   return () => {
  //     script.removeEventListener('load', handleScriptLoad);
  //     document.body.removeChild(script);

  //     [
  //       'embedded-messaging',
  //       'embeddedMessagingSiteContextFrame',
  //       'embeddedMessagingFilePreviewFrame',
  //     ].forEach(id => {
  //       const element = document.getElementById(id);
  //       if (element) {
  //         document.body.removeChild(element);
  //       }
  //     });
  //   };
  // }, []);

  const getImage = getMarkdown(page, 'ckhnu3xuo06pq0a71hadpt6wq', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckhnsie3c045c0a71jpqekuhb" }) {
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <DiagnosticoAsaSul
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
